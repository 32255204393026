<template>
  <div class="faq-container">
    <Header active="About"></Header>
    <div class="about-content">
      <div class="top-image">
        <div class="title">About Us</div>
        <div>
          <p>RECOGNIZED AND DULY REGISTERED UNDER THE NIGERIAN LAW</p>
          <p>Registration info:WANG LIN TECHNOLOGIES, RC NUMBER: 3505320</p>
        </div>
      </div>
      <div class="about-modules we-are-who">
        <div class="modules-title">Who we are，We are Giftcard8</div>
        <div class="modules-content we-are-who-modules">
          <div class="paragraphs-one">
            <div class="paragraphs-text">
              <p>
                Founded in 2018, from one person’s idea to a professional and
                skilled team, we worked hard for countless days and nights, was
                created to provide an efficient and convenient trading platform
                for customers around the world.
              </p>
              <p>
                GIFTCARD8 has the best exchange rate in the market and has
                received nearly 300K customers since its establishment and
                completed orders up to 2 million. Today, we are confident to
                show our achievements for you. But our steps would never stop,
                will always firm our original ideal, try our best to make
                customers’ ideal into reality.
              </p>
              <p>
                “We always insist on security ,instant and the best rate, how to
                improve the experience feeling of using our app is key to
                everything !”, Yang,the team leader, said at the press
                conference. We exist to create the wonderful tools that help
                people start a more convenient life.
              </p>
            </div>
            <el-image
              :src="require('../../assets/about/we_are_who_01.png')"
              fit="scale-down"
              lazy
            ></el-image>
          </div>
          <div class="paragraphs-two">
            <el-image
              :src="require('../../assets/about/we_are_who_02.png')"
              fit="scale-down"
              lazy
            ></el-image>
            <div class="paragraphs-text">
              <p>
                A good tool cannot be separated from an excellent team behind
                it. We were a team from China, began in 2018.All we have are
                four computers, three people and our big ambitions, Giftcard8
                was born. Today, our team has grown from 3 to 100 people and
                moved from small rooms to the best office buildings downtown. As
                the Chinese saying goes, doing business is about honesty. The
                word honesty has long been engraved into the bones of our
                Chinese people. We have always believed that as long as we
                sincerely do one thing, we will be able to get rewards. We
                gathered elites from all over the country, and went through
                layers of selection, in order to create a professional team, not
                only good technology, the most important thing is that our
                values should be consistent. <br />We stick to our original
                intention，never stop.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="about-modules our-story">
        <div class="modules-title">OUR STORY</div>
        <div class="modules-content our-story-modules">
          <ul class="our-story-list">
            <li
              class="our-story-items"
              v-for="(items, index) in ourStory"
              :key="index"
            >
              <el-image :src="items.url" lazy></el-image>
              <div class="our-story-year">{{ items.year }}</div>
              <div class="our-story-desc">{{ items.desc }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="about-modules how-we-works">
        <div class="how-we-works-modules">
          <div class="how-we-works-desc">
            <div class="how-we-works-title">How we works</div>
            <p>
              A gift card is a prepaid debit card that is loaded with a specific
              amount, which can then be used to make purchases and other
              financial transactions. Gift cards are highly popular, as the
              second-most given gift by consumers, the most-wanted gift by
              women, and the third-most wanted by males. At this moment in time,
              someone must be rummaging through a drawer for a gift card they
              don’t know how to use.
            </p>
            <p>
              Sell it to Giftcard8, and we won’t let you waste any fresh spare
              gift cards.
            </p>
          </div>
        </div>
      </div>
      <div class="about-modules how-to-sell">
        <div class="modules-title">How to sell gift cards on Giftcard8</div>
        <div class="modules-content how-to-sell-modules">
          Life is hard enough, we can’t stress ourselves out in any way. We
          break through the limitation of time and space, avoid a series of
          tedious processes, just for convenience.
          <ul class="how-to-sell-list">
            <li
              class="how-to-sell-items"
              v-for="(items, index) in howToSell"
              :key="index"
            >
              <el-image :src="items.url" lazy></el-image>
              <div class="how-to-sell-title">{{ items.title }}</div>
              <div class="how-to-sell-desc">{{ items.desc }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="about-modules why-choose-our">
        <h1>Why choose Giftcard8</h1>
        <p>
          What is the most important thing for a team? Not exquisite technology
          or professional staff, is the core value. It’s like our heart, it
          drives the beat of the whole team, lose it, we will lose everything.
        </p>
      </div>
      <div class="about-modules our-mission">
        <div class="modules-title">Our mission</div>
        <div class="modules-content our-mission-modules">
          <ul class="our-mission-list">
            <li
              class="our-mission-items"
              v-for="(items, index) in ourMission"
              :key="index"
            >
              <el-image :src="items.url"></el-image>
              <div class="our-mission-title">{{ items.title }}</div>
              <div class="our-mission-desc">{{ items.desc }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="about-modules say-yes">
        <h2>Everyone who uses it says yes</h2>
        <p>
          Since 2018, our team has served 300,000 customers and completed orders
          up to 2 million. Most gift cards are tradable; Security platform;
          Instant transaction; Amazing rebate…
        </p>
        <el-carousel :interval="5000" type="card" height="300px">
          <el-carousel-item v-for="(items, index) in sayYes" :key="index">
            <p>{{ items.message }}</p>
            <div class="account-info">
              <el-image :src="items.url"></el-image>
              <div class="name-tag">
                <div class="account-name">{{ items.name }}</div>
                <div class="account-tag">{{ items.tag }}</div>
              </div>
              <div>
                <el-rate
                  v-model="items.rate"
                  disabled
                  text-color="#ff9900"
                  score-template="{items.rate} points"
                />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="about-modules closer-us">
        <h2>Closer to us</h2>
        <p>Please choose your preferred way to find us or get to know us</p>
        <ul class="closer-us-list">
          <li
            class="closer-us-items"
            v-for="(items, index) in closerUs"
            :key="index"
          >
            <el-tooltip
              class="box-item"
              effect="light"
              :content="items.tooltip"
              placement="top"
            >
              <el-image
                :src="items.url"
                @click="linkTo(items.path)"
                lazy
              ></el-image>
            </el-tooltip>
          </li>
        </ul>
      </div>
      <!-- <div class="card-list">
        <div class="tips">You can trade many kinds of gift cards :</div>
        <el-row :gutter="20">
          <el-col :span="4" class="hvr-grow" v-for="(item, index) in rateListData" :key="index">
            <div class="grid-content">
              <img :src="item.category_image" />
            </div>
          </el-col>
        </el-row>
      </div> -->
      <el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";
// import md5 from "js-md5";
// import { rateList } from "@/api/index";

export default defineComponent({
  name: "About",
  components: { Header, Footer },
  setup() {
    onMounted(() => {
      document.documentElement.scrollTop = 0;
      // getRateList();
    });
    const ourStory = reactive([
      {
        url: require("../../assets/about/our_story_01.png"),
        year: "2018",
        desc:
          "Giftcard8 was founded in 2018 by Chief Yang. On WhatsApp, we received our first customer and completed our first successful transaction.",
      },
      {
        url: require("../../assets/about/our_story_02.png"),
        year: "2019",
        desc:
          "Giftcard8 is gradually recognized, we started accepting 50 kinds of cards and receives more than 100k customers.",
      },
      {
        url: require("../../assets/about/our_story_03.png"),
        year: "2021",
        desc:
          "With the prosperity of Giftcard8, Our team has become stronger. We transformed into a brand new platform that makes selling your gift cards a easy and safe process. So we created our own trading site.",
      },
      {
        url: require("../../assets/about/our_story_04.png"),
        year: "2022",
        desc:
          "Giftcard8 launched an APP for trading gift cards. With the APP, gift card transactions can be carried out anytime and anywhere.",
      },
    ]);
    const howToSell = reactive([
      {
        url: require("../../assets/about/how_to_sell_01.png"),
        title: "Create your account",
        desc:
          "When you use a brand new APP, you need to register your own account,  create your own user ID and password, and remember it.",
      },
      {
        url: require("../../assets/about/how_to_sell_02.png"),
        title: "Submit your orders",
        desc:
          "Upload your gift card with a clear card code, we will give you feedback within 3-5 minutes.",
      },
      {
        url: require("../../assets/about/how_to_sell_03.png"),
        title: "Withdraw your money",
        desc:
          "After the order is successful, the system will automatically send the amount you deserve to your wallet, you need to bind the bank, and withdraw the money you need, this process is safe and reliable.",
      },
    ]);
    const ourMission = reactive([
      {
        url: require("../../assets/about/our_mission_01.png"),
        title: "Be your knight",
        desc:
          " You can find your buyers anywhere, but just as you already know, trusted buyers aren’t that easy to come by. Under our umbrella, your gift cards and money are protected, and we have a top security system. Everything you fear doesn’t exist in Giftcard8.",
      },
      {
        url: require("../../assets/about/our_mission_02.png"),
        title: "Be your friend",
        desc:
          "Here we are not only trading relations, we are willing to be your friend, sincerely listen to your questions and proposal. In addition to a pleasant trading experience, we put the best exchange rates and highest rebates on the market in front of you, saving you a lot of money.",
      },
      {
        url: require("../../assets/about/our_mission_03.png"),
        title: "Be your surfboard",
        desc:
          "A tool that works well is a tool. Getting a worthless but rich gift card isn’t the worst part, the worst part is that you can’t find the perfect tool to sell it. Giftcard8 gives you an experience similar to surfing in the ocean with a sturdy surfboard. There is no excuse for the delay, just to give you the fastest experience ever.",
      },
    ]);
    const sayYes = reactive([
      {
        message:
          "“I gave them a $10 Google gift card as a feeler, and I found they were honest and fast, but gradually I started trading $50 and $100, I think they are trustworthy buyers.”",
        url: require("../../assets/about/say_yes_01.png"),
        name: "Ola",
        tag: "Trader",
        rate: 5,
      },
      {
        message:
          "“This is the best app I have use so far to trade my gift cards. So go on and download app.”",
        url: require("../../assets/about/say_yes_02.png"),
        name: "Bigjoe",
        tag: "Trader",
        rate: 5,
      },
      {
        message:
          "“I've been trading with these guys for over a year，I love their new app, I use it almost every day, honest as ever and much more convenient than before.”",
        url: require("../../assets/about/say_yes_03.png"),
        name: "Allen",
        tag: "Trader",
        rate: 5,
      },
    ]);
    const closerUs = reactive([
      {
        url: require("../../assets/about/closer_us_whatapp.png"),
        tooltip: "WhatsApp:+8618179849917",
        path: "",
      },
      {
        url: require("../../assets/about/closer_us_email.png"),
        tooltip: "Email:Gcard8business@giftcard8.com",
        path: "",
      },
      {
        url: require("../../assets/about/closer_us_facebook.png"),
        tooltip: "Facebook",
        path: "https://www.facebook.com/Giftcard8-business-115623504478430",
      },
      {
        url: require("../../assets/about/closer_us_twitter.png"),
        tooltip: "Twitter",
        path: "https://twitter.com/Giftcard_8",
      },
      {
        url: require("../../assets/about/closer_us_instagram.png"),
        tooltip: "Instagram",
        path: "https://www.instagram.com/giftcard8business/",
      },
      {
        url: require("../../assets/about/closer_us_blog.png"),
        tooltip: "Blog",
        path: "https://blog.giftcard8.com",
      },
      {
        url: require("../../assets/about/closer_us_youtube.png"),
        tooltip: "YouTuBe",
        path: "https://www.youtube.com/channel/UCydwz1BiDx7m4baDA7Cl2vA",
      },
      {
        url: require("../../assets/about/closer_us_tiktok.png"),
        tooltip: "Tiktok",
        path: "https://www.tiktok.com/@giftcard8app?_t=8Woc0N52bwg&_r=1",
      },
    ]);
    const linkTo = (path) => {
      if (path) {
        window.open(path);
      }
    };
    // let rateListData = reactive([]);
    // const md5sign = reactive(
    //   md5(
    //     "appid=web-v1&country_id=" +
    //       window.sessionStorage.getItem("country_id") +
    //       "f55b967cad863f21a385e904dceae165"
    //   )
    // );
    // const data = reactive({
    //   appid: "web-v1",
    //   country_id: window.sessionStorage.getItem("country_id"),
    //   sign: md5sign,
    // });
    // const getRateList = () => {
    //   rateList(data).then((res) => {
    //     if (res.data.data.length > 0) {
    //       res.data.data.forEach((item) => {
    //         item.category_logo = require("../../assets/cardlogo/" +
    //           item.category_logo +
    //           ".png");
    //         rateListData.push(item);
    //       });
    //     } else {
    //       console.log("rate is No Data");
    //     }
    //   });
    // };
    return {
      // rateListData
      ourStory,
      howToSell,
      ourMission,
      sayYes,
      closerUs,
      linkTo,
    };
  },
});
</script>

<style lang="less">
.faq-container {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  overflow-y: auto;
  ul,
  li {
    list-style: none;
  }
  .about-content {
    font-size: 24px;
    .top-image {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-image: url("../../assets/images/top_img_bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-size: 20px;
      .title {
        margin-bottom: 36px;
        font-size: 60px;
      }
    }
    .about-modules {
      padding: 86px 200px;
      .modules-title {
        text-align: left;
        color: #0f4392;
        font-size: 36px;
        font-weight: bold;
      }
      .modules-content {
        margin-top: 50px;
      }
    }
    .we-are-who {
      background: #fbfcff;
      .we-are-who-modules {
        .paragraphs-one {
          display: flex;
          .paragraphs-text {
            flex: 1;
            min-width: 40%;
            text-align: left;
            font-size: 1.6rem;
            & p + p {
              margin-top: 30px;
            }
          }
          .el-image {
            width: 900px;
            height: 576px;
            margin-left: 30px;
          }
        }
        .paragraphs-two {
          margin-top: 50px;
          display: flex;
          .el-image {
            width: 580px;
            height: 334px;
          }
          .paragraphs-text {
            flex: 1;
            text-align: left;
            font-size: 1.6rem;
            margin-left: 30px;
            & p + p {
              margin-top: 30px;
            }
          }
        }
      }
    }
    .our-story {
      background-color: #ffffff;
      .our-story-modules {
        .our-story-list {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .our-story-items {
            flex: 1;
            padding: 0 40px;
            .el-image {
              width: 230px;
              height: 230px;
              border-radius: 50%;
            }
            .our-story-year {
              margin: 20px 0;
              color: #0f4392;
              font-size: 34px;
              font-weight: bold;
            }
            .our-story-desc {
              line-height: 24px;
              font-size: 20px;
            }
          }
        }
      }
    }
    .how-we-works {
      background: url("../../assets/about/how_we_ works_bg.png") no-repeat;
      background-position: left top;
      background-size: 100% 100%;
      .how-we-works-modules {
        height: 780px;
        padding: 0 104px;
        display: flex;
        align-items: center;
        background: url("../../assets/about/how_we_works_content_bg.png")
          no-repeat;
        background-position: left top;
        background-size: 100% 100%;
        border-radius: 20px;
        text-align: left;
        box-shadow: 0px 151px 218px 1px rgba(0, 45, 206, 0.25882352941176473);
        // box-shadow: 0 0 0 #002DCE;
        .how-we-works-desc {
          width: 50%;
          .how-we-works-title {
            color: #0f4392;
            font-size: 32px;
            font-weight: bold;
          }
          p {
            margin-top: 20px;
          }
        }
      }
    }
    .how-to-sell {
      background-color: #ffffff;
      .how-to-sell-modules {
        color: #0f4392;
        text-align: left;
        .how-to-sell-list {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .how-to-sell-items {
            flex: 1;
            text-align: center;
            .el-image {
              height: 400px;
              box-shadow: 0 0 10px #ccc;
            }
            .how-to-sell-title {
              margin: 20px 0;
              font-size: 34px;
              font-weight: bold;
              color: #0f4392;
            }
            .how-to-sell-desc {
              line-height: 30px;
              font-size: 20px;
              color: #333333;
            }
          }
          & li + li {
            margin-left: 40px;
          }
        }
      }
    }
    .why-choose-our {
      height: 460px;
      padding: 0 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url("../../assets/about/how_choose_our_bg.png") no-repeat;
      background-position: left top;
      background-size: 100% 100%;
      color: #fff;
      p {
        margin-top: 40px;
      }
    }
    .our-mission {
      background-color: #fbfcff;
      .our-mission-modules {
        .our-mission-list {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .our-mission-items {
            flex: 1;
            min-height: 478px;
            padding: 33px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.0784313725490196);

            .our-mission-title {
              margin-top: 20px;
              font-size: 32px;
              font-weight: bold;
              color: #0f4392;
            }
            .our-mission-desc {
              margin-top: 30px;
              padding-top: 30px;
              line-height: 32px;
              border-top: 1px solid #2b59ff;
              font-size: 16px;
              color: #000b33;
            }
          }
          & li + li {
            margin-left: 40px;
          }
        }
      }
    }
    .say-yes {
      padding: 160px 200px;
      background: url("../../assets/about/say_yes_bg.png") no-repeat;
      background-position: left top;
      background-size: 100% 100%;
      h2 {
        color: #0f4392;
      }
      p {
        margin-top: 40px;
        font-size: 16px;
        color: #000b33;
      }
      .el-carousel {
        margin-top: 40px;
        padding: 40px;
        .el-carousel__item {
          display: flex;
          flex-direction: column;
          width: 600px;
          padding: 0 40px;
          background-color: #fff;
          box-shadow: 0px 4px 40px 1px rgba(43, 89, 255, 0.0784313725490196);
          .account-info {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            padding: 0 40px;
            .name-tag {
              flex: 1;
              padding-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .account-tag {
                color: #ccc;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .closer-us {
      height: 500px;
      background: linear-gradient(91deg, #0f4392 0%, #174ea2 30%, #1e68d8 100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      p {
        margin: 40px 0;
      }
      .closer-us-list {
        display: flex;
        .closer-us-items {
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
    // .card-list {
    //   position: relative;
    //   overflow: hidden;
    //   margin: 20px 200px;
    //   padding: 30px;
    //   text-align: left;
    //   font-weight: bold;
    //   font-size: 26px;
    //   background-color: rgba(255, 255, 255, 1);
    //   border-radius: 10px;
    //   .tips {
    //     margin-bottom: 20px;
    //   }
    //   .el-row {
    //     margin-bottom: 20px;
    //   }
    //   .el-row:last-child {
    //     margin-bottom: 0;
    //   }
    //   .el-col {
    //     border-radius: 4px;
    //     margin-bottom: 20px;
    //   }
    //   .bg-purple-dark {
    //     background: #99a9bf;
    //   }
    //   .bg-purple {
    //     background: #d3dce6;
    //   }
    //   .bg-purple-light {
    //     background: #e5e9f2;
    //   }
    //   .grid-content {
    //     position: relative;
    //     overflow: hidden;
    //     display: flex;
    //     border-radius: 4px;
    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    //   .row-bg {
    //     padding: 10px 0;
    //     background-color: #f9fafc;
    //   }
    // }
  }
}
</style>
